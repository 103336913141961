import { EnvironmentConfig } from './environment';

const apiUrl = 'https://api.chargeafter.com/v2'; //process.env.NX_PUBLIC_API_URL;
const modalUrl = 'https://pm.chargeafter.com'; //process.env.NX_PUBLIC_IFRAME_URL;

const iframe: EnvironmentConfig['iframe'] = {
  url: modalUrl,
  id: 'ca-modal-iframe', // process.env.NX_PUBLIC_IFRAME_ID,
  style: 'position: fixed;top: 0;left:0;width: 100%;height: 100%;z-index: 10010000000;border: 0;box-sizing: border-box;background-color: #00000038;display:none;', //process.env.NX_PUBLIC_IFRAME_STYLE,
};

export const environment: EnvironmentConfig = {
  production: process.env.NODE_ENV === 'production',
  containerSelector: '.ca-promotional-widget', // process.env.NX_PUBLIC_CONTAINER_SELECTION,
  buttonSelector: '.ca-checkout-button', // process.env.NX_PUBLIC_BUTTON_SELECTOR,
  specialContainerSelector: '.ca-lenovo-promotional-widget',
  apiUrl,
  promotionUrl: `${apiUrl}/promotional-info`, //${process.env.NX_PUBLIC_PROMOTION_URL_PATH}
  cdnUrl: 'https://cdn.chargeafter.com', // process.env.NX_PUBLIC_CDN_URL,
  logsUrl: 'https://logging-proxy-ca-production-170512-af60esna.uc.gateway.dev/log', // process.env.NX_PUBLIC_LOGS_URL,
  version: process.env.NX_PUBLIC_VERSION,
  envName: 'production', //process.env.NX_PUBLIC_ENV_NAME,
  externalScriptId: 'ca-external-script', //process.env.NX_PUBLIC_EXTERNAL_SCRIPT_ID,
  iframe,
};
